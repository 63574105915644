import { Injectable } from '@angular/core';
import {
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpInterceptor
} from '@angular/common/http';
import { Observable, Subscription } from 'rxjs';
import { finalize } from 'rxjs/operators';
import { SpinnerOverlayService } from 'src/shared/components/spinner-overlay/spinner-overlay.service';

@Injectable()
export class SpinnerOverlayInterceptor implements HttpInterceptor {

  constructor(private readonly spinnerOverlayService: SpinnerOverlayService) {}

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    if (!req.headers.has('noSpinner')) {
      const spinnerSubscription: Subscription = this.spinnerOverlayService.spinner$.subscribe();
      return next.handle(req).pipe(finalize(() => spinnerSubscription.unsubscribe()));
    } else {
      return next.handle(req);
    }
  }
}
