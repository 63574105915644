import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { MsalGuard } from '@azure/msal-angular';
import { AuthGuard } from 'src/auth/auth.guard';
import { NavigationGuard } from 'src/auth/navigation.guard';
import { RoleGuard } from 'src/auth/role.guard.ts';
import { AccountActivationComponent } from './open/account-activation/account-activation.component';
import { EmptyComponent } from './open/empty/empty.component';
import { LandingComponent } from './open/landing/landing.component';
import { LoginComponent } from './open/login/login.component';
import { RecoverPasswordComponent } from './open/recover-password/recover-password.component';

const routes: Routes = [
  {
    path: '',
    component: LandingComponent,
    canDeactivate: [NavigationGuard]
  },
  {
    path: 'login',
    component: LoginComponent,
    canDeactivate: [NavigationGuard]
  },
  {
    path: 'empty',
    component: EmptyComponent,
    canDeactivate: [NavigationGuard]
  },
  {
    path: 'recover-password',
    component: RecoverPasswordComponent,
    canDeactivate: [NavigationGuard]
  },
  {
    path: 'account-activation',
    component: AccountActivationComponent,
    canDeactivate: [NavigationGuard]
  },
  {
    path: 'home',
    loadChildren: () => import('./features/features.module').then(m => m.FeaturesModule),
    canActivate: [AuthGuard, RoleGuard],
    canDeactivate: [NavigationGuard],
  }
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
