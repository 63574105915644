import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { NgModule } from '@angular/core';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';

import { HTTP_INTERCEPTORS, HttpClientModule } from '@angular/common/http';
import { IPublicClientApplication, PublicClientApplication, InteractionType, BrowserCacheLocation, LogLevel } from '@azure/msal-browser';
import { MsalGuard, MsalBroadcastService, MsalModule, MsalService, MSAL_GUARD_CONFIG, MSAL_INSTANCE, MsalGuardConfiguration, MsalRedirectComponent } from '@azure/msal-angular';

import { LoginComponent } from './open/login/login.component';
import { LandingComponent } from './open/landing/landing.component';
//import { RecoverPasswordComponent } from './open/recover-password/recover-password.component';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { MaterialModule } from '../shared/material/material.module';
import { SharedComponentsModule } from 'src/shared/components/shared-components.module';
import { environment } from 'src/environments/environment';
import { NgxMaskModule } from 'ngx-mask';
import { SpinnerOverlayInterceptor } from './interceptors/spinner-overlay.interceptor';
import { AuthorizationInterceptor } from './interceptors/authorization.interceptor';
import { DatePipe } from '@angular/common';
import { AuthGuard } from 'src/auth/auth.guard';
import { EmptyComponent } from './open/empty/empty.component';
import { RecoverPasswordComponent } from './open/recover-password/recover-password.component';
import { MAT_FORM_FIELD_DEFAULT_OPTIONS } from '@angular/material/form-field';
import { AccountActivationComponent } from './open/account-activation/account-activation.component';


const isIE = window.navigator.userAgent.indexOf("MSIE ") > -1 || window.navigator.userAgent.indexOf("Trident/") > -1;

export function loggerCallback(logLevel: LogLevel, message: string) {
  console.log(message);
}

export function MSALInstanceFactory(): IPublicClientApplication {
  return new PublicClientApplication({
    auth: {
      clientId: environment.b2cClientId,
      authority: environment.signInAuthority,
      redirectUri: environment.msalUrl,
      postLogoutRedirectUri: environment.redirectUrl,
      knownAuthorities: [environment.authorityDomain]
    },
    cache: {
      cacheLocation: BrowserCacheLocation.LocalStorage,
      storeAuthStateInCookie: isIE, // set to true for IE 11
    },
    system: {
      loggerOptions: {
        loggerCallback,
        logLevel: LogLevel.Info,
        piiLoggingEnabled: false
      }
    }
  });
}

export function MSALGuardConfigFactory(): MsalGuardConfiguration {
  return {
    interactionType: InteractionType.Redirect,
    authRequest: {
      scopes: [...environment.scopes],
      prompt: 'select_account',
    },
  };
}

@NgModule({
  declarations: [
    AppComponent,
    LoginComponent,
    LandingComponent,
    EmptyComponent,
    RecoverPasswordComponent,
    AccountActivationComponent,
  ],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    AppRoutingModule,
    HttpClientModule,
    MsalModule,
    NgbModule,
    MaterialModule,
    SharedComponentsModule,
    NgxMaskModule.forRoot()
  ],
  providers: [
    {
      provide: MSAL_INSTANCE,
      useFactory: MSALInstanceFactory
    },
    {
      provide: MSAL_GUARD_CONFIG,
      useFactory: MSALGuardConfigFactory
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: SpinnerOverlayInterceptor,
      multi: true,
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: AuthorizationInterceptor,
      multi: true,
    },
    {
      provide: MAT_FORM_FIELD_DEFAULT_OPTIONS,
      useValue: { floatLabel: 'always' },
    },
    MsalService,
    MsalGuard,
    AuthGuard,
    MsalBroadcastService,
    DatePipe,
  ],
  bootstrap: [AppComponent, MsalRedirectComponent],
  exports: [
  ]
})
export class AppModule { }