<div class="carousel-c pt-2 h-80vh">
    <ngb-carousel *ngIf="images" [showNavigationIndicators]="false">
        <ng-template ngbSlide>
          <div class="picsum-img-wrapper w-100">
            <img class="w-100 c-image" [src]="images[0]" alt="Random first slide">
          </div>
          <div class="carousel-caption">
            <h3 class="title">DIGITAL PARTNERSHIP</h3>
            <p class="subtitle mt-4">CONTROLE DIGITAL DE CLIENTES</p>
          </div>
        </ng-template>
        <ng-template ngbSlide>
          <div class="picsum-img-wrapper">
            <img class="w-100 c-image" [src]="images[1]" alt="Random second slide">
          </div>
          <div class="carousel-caption">
            <h3 class="title">DIGITAL PARTNERSHIP</h3>
            <p class="subtitle mt-4">CONTROLE DIGITAL DE CLIENTES</p>
          </div>
        </ng-template>
        <ng-template ngbSlide>
          <div class="picsum-img-wrapper">
            <img class="w-100 c-image" [src]="images[2]" alt="Random third slide">
          </div>
          <div class="carousel-caption">
            <h3 class="title">DIGITAL PARTNERSHIP</h3>
            <p class="subtitle mt-4">CONTROLE DIGITAL DE CLIENTES</p>
          </div>
        </ng-template>
    </ngb-carousel>
    <!-- <div class="d-flex p-3 justify-content-center">
      <button mat-button class="button-card mx-1" [ngStyle]="{background: 'url(' + 'https://images.unsplash.com/photo-1603074255852-8533d6fbd012?ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&ixlib=rb-1.2.1&auto=format&fit=crop&w=750&q=80' + ')'}"></button>
      <button mat-button class="button-card mx-1" [ngStyle]="{background: 'url(' + 'https://images.unsplash.com/photo-1603074255852-8533d6fbd012?ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&ixlib=rb-1.2.1&auto=format&fit=crop&w=750&q=80' + ')'}"></button>
      <button mat-button class="button-card mx-1" [ngStyle]="{background: 'url(' + 'https://images.unsplash.com/photo-1603074255852-8533d6fbd012?ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&ixlib=rb-1.2.1&auto=format&fit=crop&w=750&q=80' + ')'}"></button>
    </div> -->
    <div *ngIf="cards">
      <!-- Mobile section : one image per slide -->
      <ngb-carousel class="mt-2" *ngIf="mobile" [showNavigationArrows]="false" [showNavigationIndicators]="false">
          <ng-template ngbSlide *ngFor="let card of cards">
              <div>
                  <div class="col-xs-3 col-md-3 col-lg-3 col-sm-6 p-0">
                    <a [href]="card.url">
                      <img style="width: 100vw;" src="{{card.imagem}}">
                    </a>
                  </div>
              </div>
          </ng-template>
      </ngb-carousel>
  
      <!-- Desktop section : multiple images per slide -->
      <ngb-carousel *ngIf="!mobile" [showNavigationArrows]="false" [showNavigationIndicators]="false">
          <ng-template ngbSlide *ngFor="let group of cardsFormatted">
            <div class="d-flex justify-content-center">
              <div class="m-2" *ngFor="let card of group">
                <a [href]="card.url">
                  <img style="width: 250px; height: 125px;" src="{{card.imagem}}">
                </a>
              </div>
            </div>
          </ng-template>
      </ngb-carousel>
  </div>
</div>