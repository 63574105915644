import { Component, OnInit } from '@angular/core';
import { Subscription } from 'rxjs';
import { HomeService } from 'src/app/features/home/home.service';

@Component({
  selector: 'app-landing',
  templateUrl: './landing.component.html',
  styleUrls: ['./landing.component.scss']
})
export class LandingComponent implements OnInit {
  subscription!: Subscription;
  mobile = false;
  cards: any = [];
  cardsFormatted: any;
  images = [
    'assets/img/ImgHome.jpg',
    'assets/img/ImgHome.jpg',
    'assets/img/ImgHome.jpg',
  ]

  constructor(private homeService: HomeService) { }

  ngOnInit(): void {
    if (window.screen.width === 360) { // 768px portrait
      this.mobile = true;
    }
    this.manageCardsSlider();
    // this.getCards();
  }

  manageCardsSlider() {
    this.cards = [
      {
        imagem: 'assets/img/O_Portal.jpg',
        url: '/'
      },
      {
        imagem: 'assets/img/Como_Usar.jpg',
        url: '/'
      },
      {
        imagem: 'assets/img/Fale_Conosco_of.jpg',
        url: '/'
      }
    ];
    this.cardsFormatted = [];
    var j = -1;
    
    for (var i = 0; i < this.cards.length; i++) {
        if (i % 3 == 0) {
            j++;
            this.cardsFormatted[j] = [];
            this.cardsFormatted[j].push(this.cards[i]);
        }
        else {
            this.cardsFormatted[j].push(this.cards[i]);
        }
    }
  }

  getCards() {
    // this.subscription = this.homeService.getCards().subscribe(
    //   data => {
    //     this.cards = data.data;
    //     this.manageCardsSlider();
    //   },
    //   err => {
    //   console.log(err.error)
    //   }
    // );
  }

  ngOnDestroy() {
    // this.subscription.unsubscribe();
  }
}
